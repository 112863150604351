
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddRfqModal from "@/components/modals/forms/AddRfqModal.vue";
import ProductForComparisionModal from "@/components/modals/forms/ProductForComparisonModal.vue";

import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { Modal } from "bootstrap";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import moment from "moment";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    // AddRfqModal,
    ProductForComparisionModal,
    AddProductListSalesEnquiry,
    MixedWidget10,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const showDialogOffer = ref<boolean>(false);
    const showDialogAddOffer = ref<boolean>(false);
    const showDialogDetail = ref<boolean>(false);
    const detailTitle = ref("");
    const router = useRouter();
    const offerTitle = ref("");
    const addOfferTitle = ref("");
    const payment_term_data = ref([]);
    const purchase_quotation_id = ref("");
    const uomId = ref("");
    const offerQty = ref("");
    const purchase_product_master_id = ref("");
    const branch_data = ref([]);
    const supplier_data = ref<any>([]);
    const brandName = ref("");
    const catName = ref("");
    const productName = ref("");

    const formRef = ref<null | HTMLFormElement>(null);
    const expeceted_delivery_data = ref([]);
    const incoterms_data = ref([]);

    var details_list_data = ref<Array<DETIALS>>([]);
    var offer_list_data = ref<Array<OFFER>>([]);

    const searchTearm = ref("");
    const listVisible = ref<boolean>(true);
    const offerData = ref({
      required_qty: ref<any>(""),
      rate: ref<any>(""),
      loading: ref<any>(""),
      frieght: ref<any>(""),
      insurance: ref<any>(""),
      packing: ref<any>(""),
      unloading: ref<any>(""),
      tcs: ref<any>(""),
      weighing: ref<any>(""),
      total_rate: ref<any>(""),
    });
    const formData = ref({
      expire_date_select: "",
      payment_term_select: "",
      incoterms_select: "",
      delivery_term_select: "",
      final_amount: "",
      supplier_select: "",
      supplier_warehouse_select: "",
      required_qty: ref<any>(""),
      rate: ref<any>(""),
      remark: "",
      startDate: "",
    });
    const rules = ref({
      expire_date_select: [
        {
          required: true,
          message: "Expiry is required",
          trigger: "change",
        },
      ],
      payment_term_select: [
        {
          required: true,
          message: "Payment Term is required",
          trigger: "change",
        },
      ],
      incoterms_select: [
        {
          required: true,
          message: "Incoterm is required",
          trigger: "change",
        },
      ],
      delivery_term_select: [
        {
          required: true,
          message: "Delivery Term is required",
          trigger: "change",
        },
      ],
      required_qty: [
        {
          required: true,
          message: "Required Qty is required",
          trigger: "change",
        },
      ],
      rate: [
        {
          required: true,
          message: "Rate is required",
          trigger: "change",
        },
      ],
      supplier_select: [
        {
          required: true,
          message: "Final Amount is required",
          trigger: "change",
        },
      ],
      supplier_warehouse_select: [
        {
          required: true,
          message: "Final Amount is required",
          trigger: "change",
        },
      ],
      final_amount: [
        {
          required: true,
          message: "Final Amount is required",
          trigger: "change",
        },
      ],
    });
    getPaymentTerms();
    getIncoterms();
    getDeliveryTerms();
    interface DETIALS {
      purchase_product_master_id: string;
      purchase_indent_no: string;
      product_name: string;
      brand_name: string;
      required_qty: string;
      uom: string;
      status: string;
    }

    interface OFFER {
      quotation_offer_id: string;
      supplier_company: string;
      supplier_company_branch_rs: string;
      offer_date: string;
      offer_valid_till: string;
      offer_qty: string;
      uom: string;
      offer_total_rate: string;
      offer_remark: string;
      incoterm: string;
      payment_term: string;
      delivery_term: string;
    }

    interface WIRFQ {
      pq_id: string;
      pq_no: string;
      pq_date: string;
      Pr_no: string;
      incoterm: string;
      buyer_consignee_yn: string;
      consignee_warehouese: string;
      maintained_by: string;
    }

    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WIRFQ>>([]);

    const getCompanyList = async (data) => {
      loadingData.value = true;
      let startDate = '';
      let endDate = '';
      if(formData.value['startDate']?.length){
        startDate = moment(formData?.value?.startDate[0])?.format("YYYY-MM-DD")
        endDate = moment(formData?.value?.startDate[1])?.format("YYYY-MM-DD")
      }

      try {
        var values = {
          user_id: 1,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          pq_no:formData.value['rfq_no'],
          start_date: startDate,
          end_date: endDate
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_REQUEST_FOR_QUOTATION_LIST, values)
          .then((data) => {
            console.log("total data:" + JSON.stringify(data));

            // console.log("total data:" + response.totalCount);
            // const data = response;
            // data.data = data.data.map((item) => {
            //   let reasonValue = reason_data.value.filter((reason) => {
            //     return (
            //       reason.stock_movement_reason_id ===
            //       item.stock_movement_reason_id
            //     );
            //   });
            //   item.stock_movement_reason =
            //     reasonValue[0]?.stock_movement_reason;
            //   item.is_transport_applicable = item?.is_transport_applicable
            //     ? "Yes"
            //     : "No";
            //   return item;
            // });
            tableData.value = [];
            const totalCount = data?.data?.total_records;
            // set pagination
            paginationData.value["total"] = data?.data?.total_records;
            paginationData.value["start"] = data?.data?.records_from;
            paginationData.value["end"] = data?.data?.records_upto;
            paginationData.value["activePage"] = data?.data?.page;
            paginationData.value["totPage"] = data?.data?.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data?.data?.bar_range_start;
              index < data?.data?.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data?.data?.result_list;
            var resultsM = ref<Array<WIRFQ>>([]);

            for (let j = 0; j < data.length; j++) {
              resultsM.value = Array({
                pq_id: data[j]["pq_id"],
                pq_no: data[j]["pq_no"],
                pq_date: data[j]["pq_date"],
                Pr_no: data[j]['pr_no'],
                incoterm: data[j]["incoterm"],
                buyer_consignee_yn: data[j]["buyer_consignee_yn"],
                consignee_warehouese: data[j]["consignee_warehouese"],
                maintained_by: data[j]["maintained_by"],
              });
              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setCompanyData = async (data) => {
      // let product_list = [];
      debugger;
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        user_id: user.user_id,
        purchase_product_master_id: purchase_product_master_id.value,
        purchase_quotation_id: purchase_quotation_id.value,

        offer_qty: formData.value["required_qty"],
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_warehouse_select"],
        offer_product_rate: data.rate,
        offer_loading_rate: offerData.value["loading"],
        offer_freight_rate: offerData.value["frieght"],
        offer_insurance_rate: offerData.value["insurance"],
        offer_packing_rate: offerData.value["packing"],
        offer_unloading_rate: offerData.value["unloading"],
        offer_tcs_rate: offerData.value["tcs"],
        offer_weighing_rate: offerData.value["weighing"],
        offer_total_rate: offerData.value["total_rate"],
        offered_rate: formData.value["rate"],
        offer_date: moment().format("YYYY-MM-DD"),
        offer_valid_till: moment(formData.value["expire_date_select"]).format(
          "YYYY-MM-DD"
        ), //convert date object to right format and string
        payment_term_id: formData.value["payment_term_select"],
        incoterm_id: formData.value["incoterms_select"],
        delivery_term_id: formData.value["delivery_term_select"],
        offer_remark: formData.value["remark"],
      };
      console.log(JSON.stringify(db_data));
      try {
          await store.dispatch(ActionsFi.CUST_CREATE_UPDATE_PURCHASE_QUOTATION_OFFER, db_data)
          .then(({ data }) => {
            console.log(data);
            setTimeout(() => {
              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                // hideModal(addPlanningIndentRef.value);
                getOffersList(purchase_product_master_id.value);
                showDialogAddOffer.value = false;
              });
            }, 2000);
          })
          .catch(({ response }) => {
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        console.log(e);
      }
    };
    const initCustomers = ref<Array<WIRFQ>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Request For Quotation", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      // for (let i = 0; i < tableData.value.length; i++) {
      //   if (tableData.value[i].company_id === id) {
      //     tableData.value.splice(i, 1);
      //   }
      // }
    };

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }
    const showDetail = (stock_quotation_request_no) => {
      detailTitle.value = stock_quotation_request_no
        ? "RFQ Detail(" + stock_quotation_request_no + ")"
        : "";
      offerTitle.value = stock_quotation_request_no
        ? "Offer(" + stock_quotation_request_no + ")"
        : "";
      addOfferTitle.value = stock_quotation_request_no
        ? "Add Offer(" + stock_quotation_request_no + ")"
        : "";

      showDialogDetail.value = true;
    };

    const showOffer = () => {
      showDialogOffer.value = true;
    };

    const showAddOffer = () => {
      formData.value["required_qty"] = offerQty.value;
      showDialogAddOffer.value = true;
    };

    const onTotalChange = () => {
      offerData.value["total_rate"] =
        (formData.value["rate"] != "" && !isNaN(formData.value["rate"])
          ? parseFloat(formData.value["rate"])
          : 0) +
        (offerData.value["loading"] != "" && !isNaN(offerData.value["loading"])
          ? parseFloat(offerData.value["loading"])
          : 0) +
        (offerData.value["freight"] != "" && !isNaN(offerData.value["freight"])
          ? parseFloat(offerData.value["freight"])
          : 0) +
        (offerData.value["insurance"] != "" &&
        !isNaN(offerData.value["insurance"])
          ? parseFloat(offerData.value["insurance"])
          : 0) +
        (offerData.value["packing"] != "" && !isNaN(offerData.value["packing"])
          ? parseFloat(offerData.value["packing"])
          : 0) +
        (offerData.value["unloading"] != "" &&
        !isNaN(offerData.value["unloading"])
          ? parseFloat(offerData.value["unloading"])
          : 0) +
        (offerData.value["tcs"] != "" && !isNaN(offerData.value["tcs"])
          ? parseFloat(offerData.value["tcs"])
          : 0) +
        (offerData.value["weighing"] != "" &&
        !isNaN(offerData.value["weighing"])
          ? parseFloat(offerData.value["weighing"])
          : 0);
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    async function getIncoterms() {
      await store
        .dispatch(ActionsFi.CUST_GET_INCOTERMS_DATA)
        .then(({ data }) => {
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getDeliveryTerms() {
      await store
        .dispatch(ActionsFi.CUST_GET_DELIVERY_TERMS_DATA)
        .then(({ data }) => {
          debugger;
          expeceted_delivery_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getDetailsList(id) {
      let params = {
        purchase_quotation_id: id,
      };
      details_list_data.value = [];
      offer_list_data.value = [];
      purchase_quotation_id.value = id;
      await store
        .dispatch(ActionsFi.CUST_GET_QUOTATION_REQUEST_LIST, params)
        .then(({ data }) => {
          debugger;
          offerQty.value = data[0]?.required_qty;
          data.map((item) => {
            item.required_qty = item.required_qty + " " + item.uom;
          });
          // uomId.value = data[0].uom_id;
          uomId.value = data[0].uom;
          details_list_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    async function getPaymentTerms() {
      await store
        .dispatch(ActionsFi.CUST_GET_MM_PAYMENT_TERMS_DATA)
        .then(({ data }) => {
          debugger;
          payment_term_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          return false;
        }
      });
    };

    async function getSupplierList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      // let values = { trade_type: 1 };
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getBranchList() {
      let values = {
        company_id: formData.value["supplier_select"],
      };

      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          branch_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getOffersList(id) {
      let params = {
        // purchase_quotation_id: purchase_quotation_id.value,
        purchase_product_master_id: id,
      };
      purchase_product_master_id.value = id;
      await store
        .dispatch(ActionsFi.CUST_GET_QUOTATION_REQUEST_OFFER_LIST, params)
        .then(({ data }) => {
          debugger;
          brandName.value = data?.brand_name;
          catName.value = data?.category_name;
          productName.value = data?.product_name;
          data?.offers_list.map((item) => {
            item.offer_qty = item.offer_qty + " " + item.uom;
            item.incoterm =
              "Incoterm:" +
              item.incoterm +
              "\n" +
              "Payment Term:" +
              item.payment_term +
              "\n" +
              "Delivery Term:" +
              item.delivery_term;
          });
          offer_list_data.value = data?.offers_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const Pdfdata = async (id) => {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        "user_id" : user.user_id,
        "doc_type" : 2,
        "doc_id" : id
      };
      console.log("DB DATA")
      console.log(db_data)
      //loading.value = true;
      try {
        await store
          .dispatch(ActionsFi.CUST_PDF_REQUEST, db_data)
          .then(({ data }) => {
           console.log("DATATATATA")
           console.log(data.request_id)
           console.log('https://print.elogicals.com/print/pq/' +db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id)
            //loading.value = false;
            window.open('https://print.elogicals.com/print/pq/' +db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id )
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
      showList,
      listVisible,
      submit,
      formRef,
      hideList,
      showDialogOffer,
      showDialogAddOffer,
      showOffer,
      getOffersList,
      showDialogDetail,
      detailTitle,
      offerTitle,
      addOfferTitle,
      details_list_data,
      offer_list_data,
      showDetail,
      getDetailsList,
      purchase_quotation_id,
      uomId,
      purchase_product_master_id,
      showAddOffer,
      getPaymentTerms,
      getIncoterms,
      getDeliveryTerms,
      rules,
      payment_term_data,
      incoterms_data,
      formData,
      expeceted_delivery_data,
      router,
      offerData,
      onTotalChange,
      supplier_data,
      getSupplierList,
      branch_data,
      getBranchList,
      offerQty,
      brandName,
      catName,
      productName,
      Pdfdata
    };
  },
});
