
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import OfferCoparisionModal from "./OfferCoparisonModal.vue";
export default defineComponent({
  name: "edit-details-rfq-offer-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    OfferCoparisionModal,
  },
  methods: {},

  setup(props) {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const ProductForComparisionModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const product_offer_list = ref([]);
    const pqDetailId = ref("");

    const getOffersProductList = async () => {
      //   let params = {pq_detail_id : props.data?.pq_detail_id};
      await store
        .dispatch(ActionsFi.CUST_GET_OFFERS_PRODUCT_LIST)
        .then(({ data }) => {
          product_offer_list.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const setPrduct = (row) => {
      pqDetailId.value = row?.pq_detail_id;
    };

    onMounted(async () => {
      await getOffersProductList();
    });

    return {
      formRef,
      loading,
      ProductForComparisionModalRef,
      product_offer_list,
      pqDetailId,
      setPrduct,
    };
  },
});
