
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
export default defineComponent({
  name: "rfq-offer-comparision-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
  },
  components: {},
  methods: {},

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const RfqOfferComparisionModal = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const selectedOffers = ref<Array<any>>([]);
    const offersForComparision = ref([]);
    const productDescription = ref([]);
    const tempOrderQty = ref('');

    const getOffersForComparsion = async () => {
      let params = { pq_detail_id: props.data?.pq_detail_id };
      await store
        .dispatch(ActionsFi.CUST_GET_OFFER_CPMARISION, params)
        .then(({ data }) => {
          if(data?.length){
          let dataWithextrafileds = data?.map((item, index) => {
             return Object.assign({order_qty:'', isCheck:false}, item)
          })
            offersForComparision.value = dataWithextrafileds
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const getProductDescription = async() => {
      let params = {pq_detail_id : props.data?.pq_detail_id};
      await store
        .dispatch(ActionsFi.CUST_GET_PRODUCT_DESCRIPTION,params)
        .then(({ data }) => {
          productDescription.value = data[0];
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }


    const onCheckAndInput = (selectedRows, isFormInput=false) => {
            if(selectedRows?.order_qty === '' && !isFormInput){
              Swal.fire({
              text: "Please enter order qty first and then select",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            selectedRows.isCheck = false;
            }else if(isFormInput){
              validateNumber(selectedRows)
              removeFormSelectedOffers(selectedRows);
            }else if(!selectedRows.isCheck && !isFormInput){
              removeFormSelectedOffers(selectedRows)
            }
            else{
               selectedOffers.value.push({ 
                quotation_offer_id: selectedRows.quotation_offer_id,
                 order_quantity:selectedRows.order_qty})
            }
    }

    const validateNumber = (selectedRows) => {
      debugger;
      if ((/^\d*\.?\d*$/.test(selectedRows.order_qty))) {
        if(selectedRows.order_qty == 0){
          selectedRows.order_qty = selectedRows.order_qty.slice(0, -1);
          return;
        }
          tempOrderQty.value = selectedRows.order_qty;
      }else if(selectedRows.order_qty == ''){
         tempOrderQty.value = '';
         return;
      }
      else{
          selectedRows.order_qty = tempOrderQty.value;
      }
    };

    const removeFormSelectedOffers = (selectedRows) => {
      selectedRows.isCheck = false;
      let index = selectedOffers.value.map((deteRow) =>
      {return deteRow.quotation_offer_id}).indexOf(selectedRows?.quotation_offer_id);
      index !== -1 ? selectedOffers.value.splice(index, 1) : null;
    }

    onMounted(async () => {
      RfqOfferComparisionModal.value?.addEventListener("show.bs.modal",async function (event) {
          offersForComparision.value = [];
          productDescription.value = [];
          await getProductDescription();
          await getOffersForComparsion();
        }
      );
    });

    const submit = async () => {
      loading.value = true;
      let user = JSON.parse(JwtService.getToken());

      const requestData = {
        user_id: user.user_id,
        contract_data: selectedOffers.value
      };

      await store.dispatch(
          ActionsFi.CUST_CREATE_PURCHASE_CONTRACT,
          requestData
        )
        .then(({ data, is_error, status, message }) => {
          if (data && status !== 500) {
            setTimeout(() => {
              loading.value = false;
              hideModal(RfqOfferComparisionModal.value);
              Swal.fire({
                html: ""+
                      data?.pc_no+
                      "<br>Purchase contract created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                // router.go(0);
                router.push({ name: "apps-purchase-contract-listing" });
              });
            }, 2000);
          } else if(data == "" && is_error == false && status == 500){
            loading.value = false;
            Swal.fire({
                text: message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(RfqOfferComparisionModal.value);
                router.go(0);
              });
          }
          else {
            loading.value = false;
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          loading.value = false;
          console.log(response);
        });
    };

    return {
      submit,
      loading,
      RfqOfferComparisionModal,
      offersForComparision,
      productDescription,
      onCheckAndInput
    };
  },
});
